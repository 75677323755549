import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { Button, Card, CardActions, Paper, Stack, LinearProgress } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { useLazyGetRequestQuery, usePayRequestMutation } from '../slices/api';
import DetailCardContent from '../sections/@dashboard/request/DetailCardContent';
import RequestReports from './RequestReports';
import Request from '../utils/request';

const RequestView = ({ isUser, backwardRoute }) => {
	const { reqId } = useParams();
	const [searchParam] = useSearchParams();
	const navigate = useNavigate();
	const [payRequest, { isSuccess, isLoading, data }] = usePayRequestMutation();
	const [trigger, result] = useLazyGetRequestQuery(reqId);

	useEffect(() => {
		if (isSuccess && data) {
			window.location = data.url;
		}
	}, [isSuccess, data]);

	useEffect(() => {
		let timer;

		console.log(searchParam.get('from'), 'url');
		if (searchParam.get('from') === 'payment_success') {
			timer = setTimeout(() => {
				console.log('lazy load after 5 seconds');
				trigger(reqId);
			}, 5000);
		} else {
			trigger(reqId);
		}

		return () => clearTimeout(timer);
	}, [reqId, trigger, searchParam]);

	if (result.isLoading || !result.data) {
		return <LinearProgress />;
	}

	const request = Request.cast(result.data);
	const { tests } = request;

	const onPayRequest = () => {
		payRequest({
			requestId: request.id,
			items: tests.map((test) => ({
				id: test.id,
			})),
		});
	};

	return (
		<>
			<Helmet>
				<title> View Request - {process.env.REACT_APP_ORG} </title>
			</Helmet>
			<Paper elevation={6}>
				<Card>
					<CardActions sx={{ m: 1 }}>
						<Stack spacing={2} direction="row">
							{request.isReadyForPayment && (
								<LoadingButton
									loading={isLoading}
									variant="outlined"
									color="primary"
									onClick={onPayRequest}
									disabled={isSuccess}
								>
									<span>Pay Now</span>
								</LoadingButton>
							)}
							<Button variant="outlined" color="error" onClick={() => navigate(backwardRoute)}>
								Back
							</Button>
						</Stack>
					</CardActions>
					<DetailCardContent request={request} />
					<RequestReports request={request} isUser={isUser} />
				</Card>
			</Paper>
		</>
	);
};

export default RequestView;
