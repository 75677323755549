// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const loadNavConfig = (isAdmin) => {
	const menuItems = [
		{
			title: 'dashboard',
			path: '/dashboard/app',
			icon: icon('ic_analytics'),
		},
		{
			title: 'request',
			path: '/dashboard/requests',
			icon: icon('ic_user'),
		},
	];

	if (isAdmin) {
		menuItems.push({
			title: 'Admin',
			path: '/dashboard/admin',
			icon: icon('ic_lock'),
		});
	}

	return menuItems;
};

export default loadNavConfig;
